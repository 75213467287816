<template>
  <div class="reportConfigList">
    <ReportConfigListCompoonent/>
  </div>
</template>

<script>
// @ is an alias to /src
import ReportConfigListCompoonent from '@/components/ReportConfigListComponent.vue';

export default {
  name: 'ReportConfigListView',
  components: {
    ReportConfigListCompoonent,
  },
};
</script>
