<template>
  <div>
    <div class="reportConfigs" v-if="!loading">
      <div class="page-title">
        {{ $t("pageTitle.forms") }}
        <div class="actionBar">
          <div class="actionButton bgBrown refresh" @click="loadConfigs"><i class="fas fa-sync"></i>
            <div class='actionButtonTitle'>{{ $t('general.refresh') }}</div>
          </div>
          <div v-if="showInactiveConfigs && $store.getters.userIsAdmin" data-cy="forms-toggle-inactive" class="actionButton refresh active"
            @click="showInactiveConfigs =!showInactiveConfigs; loadConfigs();">
            <i class="fas fa-recycle"></i>
            <div class='actionButtonTitle'>{{ $t('general.inactive') }}</div>
          </div>
          <div v-else-if="$store.getters.userIsAdmin" data-cy="forms-toggle-inactive" class="actionButton bgBrown refresh"
            @click="showInactiveConfigs =!showInactiveConfigs; loadConfigs();">
            <i class="fas fa-recycle"></i><div class='actionButtonTitle'>{{ $t('general.inactive') }}</div>
          </div>
        </div>
      </div>
      <EmptyListAnimationComponent v-if="(reportConfigs != null && reportConfigs.length == 0)"/>
      <div class="screen-container" ref="screenContainer" :style='{ height: screenContainerHeight }'>
        <div class="tiles-wrapper configs">
          <router-link :to="`/reports/${rc._id}/${encodeURIComponent(rc.name)}`" v-for="rc in reportConfigs" :key="rc.reportConfigId">
            <div class="tile"  :class="{'inactive' : rc.inactive}">
                <i class="tile-icon fas fa-file-alt"></i>
                <div class="tile-name">{{rc.name}} {{rc.accessType}}</div>
                <div class="tile-info">
                  <div class="state-info all">{{ rc.reportCount != null && rc.reportCount > 0 ? rc.reportCount + ' ' + (rc.reportCount > 1 ? $t('detail.reports') : $t('detail.report')) : '' }}</div>
                  <div class="state-info new">&nbsp;{{ rc.reportStates[0] != null && rc.reportStates[0] > 0 ? rc.reportStates[0] + ' ' + $t('detail.state0') : '' }}</div>
                  <div class="state-info in-progress">&nbsp;{{ rc.reportStates[1] != null && rc.reportStates[1] > 0 ? rc.reportStates[1] + ' ' + $t('detail.state1') : '' }}</div>
                  <div class="state-info done">&nbsp;{{ rc.reportStates[2] != null && rc.reportStates[2] > 0 ? rc.reportStates[2] + ' ' + $t('detail.state2') : '' }}</div>
                </div>
                <div class="tile-icon-more" v-if="rc.isEditable" @click="openMore(rc, $event)" data-cy="config-more-dots">
                  <i class="three-dots-icon fas fa-ellipsis-v"></i>
                </div>
                <div class="tile-last-modified">{{ new Date(rc.modified).toLocaleString() }}</div>
            </div>
          </router-link>
        </div>
        <br/>
        <SpaceIndicatorComponent ref="spaceIndicator" v-if="!showInactiveConfigs && (reportConfigs != null && reportConfigs.length > 0)"/>
        <div v-if="templates.length > 0 && !showInactiveConfigs" class="separatorTitle">{{$t('general.templates')}}</div>
        <div v-if="templates.length > 0 && !showInactiveConfigs" class="tiles-wrapper">
          <div @click="openTemplate(template)" v-for="template in templates" :key="template.id">
            <div class="tile template">
                <i class="tile-icon fas fa-file"></i>
                <div class="tile-name">{{template.name}}</div>
                <div class="tile-info">
                  {{template.description}}
                </div>
            </div>
          </div>
        </div>
        <div v-if="$store.getters.userIsAdmin" @click="addNewConfig" class="floating-add-button">
          <img class="floating-add-button-icon" src="../assets/icons/plus_white.png" />
        </div>
        <!--
        <div v-if="$store.getters.userIsAdmin" @click="importFromARIS" class="floating-add-button" style="right: 140px">
          <i class="fas fa-project-diagram"></i>
        </div>
        -->
      </div>
    </div>
    <div class="loading" v-else>
      <LoadingSpinnerIndicatorComponent />
    </div>

    <div class="error" v-if="errorMessage != null">{{errorMessage}}</div>

    <div v-show="currentMoreMenu != null" @mouseleave="closeMore" class="more-menu" ref="moreMenu">
      <div v-if="currentMoreMenu != null && currentMoreMenu.data != null && currentMoreMenu.data.inactive">
        <div class="more-menu-item" data-cy="more-reactivate" @click="reactivate($event)">{{ $t("action.reactivate") }}</div>
        <div class="more-menu-item" data-cy="more-delete" @click="moreDelete($event)">{{ $t("action.delete") }}</div>
      </div>
      <div v-else-if="currentMoreMenu != null && currentMoreMenu.data">
        <div class="more-menu-item" data-cy="more-edit" @click="moreEdit($event)">{{ $t("action.designer") }}</div>
        <div v-if="$store.getters.userIsAdmin" data-cy="more-rights" class="more-menu-item" @click="moreEditRights($event)">{{ $t("action.accessRights") }}</div>
        <div v-if="$store.getters.userIsAdmin" data-cy="more-copy" class="more-menu-item" @click="copyConfig($event)">{{ $t("action.copy") }}</div>
        <div v-if="$store.getters.userIsAdmin" data-cy="more-deactivate" class="more-menu-item" @click="moreDeactivate($event)">{{ $t("action.deactivate") }}</div>
      </div>
    </div>
    <CreateOrEditReportConfigModal :reportConfigToEdit="reportConfigToEdit" ref="createOrEditReportConfigModal" @refresh="loadConfigs"/>
    <ConfirmModal ref="confirmDeleteModal" :title="$t('modal.titleDelete')" :text="$t('modal.textDeleteConfig')"/>
    <ConfirmModal ref="confirmDeactivateModal" :title="$t('modal.titleDeactivate')" :text="$t('modal.textDeactivate')"/>
    <PromptModal ref="arisModelPathModal" :title="$t('modal.titleArisModelPath')" :text="$t('modal.textArisModelPath')"/>
  </div>
</template>

<script>
/* eslint-disable no-console,no-underscore-dangle, no-alert */

import { v4 as uuidv4 } from 'uuid';
import Constants from '@/helpers/Constants';
import CryptoHelper from '@/helpers/CryptoHelper';
import ServiceHelper from '@/helpers/ServiceHelper';
import SpaceIndicatorComponent from '@/components/SpaceIndicatorComponent.vue';
import CreateOrEditReportConfigModal from '@/components/modals/CreateOrEditReportConfigModal.vue';
import ScreenHeightMixin from '@/mixins/ScreenHeightMixin.vue';
import ConfirmModal from '@/components/modals/ConfirmModal.vue';
import PromptModal from '@/components/modals/PromptModal.vue';
import LoadingSpinnerIndicatorComponent from './LoadingSpinnerIndicatorComponent.vue';
import EmptyListAnimationComponent from './EmptyListAnimationComponent.vue';

export default {
  components: {
    CreateOrEditReportConfigModal,
    LoadingSpinnerIndicatorComponent,
    ConfirmModal,
    EmptyListAnimationComponent,
    SpaceIndicatorComponent,
    PromptModal,
  },
  name: 'ReportConfigListComponent',
  mixins: [ScreenHeightMixin],
  data() {
    return {
      reportConfigs: [],
      loading: false,
      errorMessage: null,
      currentMoreMenu: null,
      reportConfigToEdit: null,
      showInactiveConfigs: false,
      templates: [],
    };
  },
  mounted() {
    this.loadConfigs();
  },
  methods: {
    async addNewConfig() {
      const t = this.$store.getters.tenant;
      if (t != null && t.subscriptionType === 'EXPIRED') {
        alert(this.$t('error.code_903'));
        return;
      }
      this.$router.push('/edit/_new');
    },
    async importFromARIS() {
      const t = this.$store.getters.tenant;
      if (t != null && t.subscriptionType === 'EXPIRED') {
        alert(this.$t('error.code_903'));
        return;
      }

      const arisModelPath = await this.$refs.arisModelPathModal.show('United Motor Group/dc6d7611-626a-11ed-3cb8-0a002700001d');
      console.log(arisModelPath);
      if (arisModelPath != null && arisModelPath.trim().length > 0) {
        // call import
        const data = await ServiceHelper.sendDataToBackend('importArisModel', { arisModelPath });
        const newConfig = data.payload.newReportConfig;

        const encryptedContent = CryptoHelper.encrypt(Buffer.from(JSON.stringify(newConfig)));
        const encryptedTitle = CryptoHelper.encrypt(Buffer.from(newConfig.name));
        const { existingId } = data.payload;

        const res = await ServiceHelper.sendDataToBackend(`reportConfig${existingId != null ? `/${existingId}` : ''}`,
          { content: encryptedContent, name: encryptedTitle, arisGuid: arisModelPath }, existingId != null ? 'PUT' : 'POST');
        console.log(res);
        this.loadConfigs();
      }
    },
    async loadConfigs() {
      this.loading = true;
      this.errorMessage = null;
      try {
        const data = await ServiceHelper.loadDataFromBackend(`reportConfigNames${this.showInactiveConfigs ? '/true' : ''}`);
        // check for error response
        this.reportConfigs = data.payload;
        this.reportConfigs.forEach((rc) => {
          const conf = rc;
          const tmpTitle = CryptoHelper.decrypt(rc.name).toString('utf-8');
          conf.name = tmpTitle;
        });
        try {
          const templateData = await ServiceHelper.loadDataFromBackend('templateNames');
          this.templates = templateData.payload;
        } catch (e) {
          console.error(e);
          this.templates = [];
        }
        if (this.$refs.spaceIndicator != null) this.$refs.spaceIndicator.refresh();
      } catch (error) {
        console.error('There was an error!', error);
        this.errorMessage = error;
      } finally {
        this.loading = false;
        this.backendOperation = null;
        setTimeout(this.resizeHandler, 100);
      }
    },
    openMore(rc, evt) {
      evt.preventDefault();
      evt.stopPropagation();
      evt.target.appendChild(this.$refs.moreMenu);
      this.currentMoreMenu = {
        data: rc,
      };
    },
    closeMore() {
      this.currentMoreMenu = null;
    },
    moreEdit(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.$router.push(`/edit/${this.currentMoreMenu.data._id}`);
      this.closeMore();
    },
    async moreEditRights(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      const reportConfigToEdit = JSON.parse(JSON.stringify(this.currentMoreMenu.data));
      const rightsData = await ServiceHelper.loadDataFromBackend(`rightsForReportConfig/${this.currentMoreMenu.data._id}`);
      reportConfigToEdit.accessRights = rightsData.payload;
      this.reportConfigToEdit = reportConfigToEdit;
      this.$refs.createOrEditReportConfigModal.show();
      this.closeMore();
    },
    async moreDelete(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      const idToDelete = this.currentMoreMenu.data._id;
      this.closeMore();
      if (await this.$refs.confirmDeleteModal.show()) {
        await ServiceHelper.deleteDataFromBackend(`reportConfig/${idToDelete}/true`);
        this.loadConfigs();
      }
    },
    async moreDeactivate(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      const idToDelete = this.currentMoreMenu.data._id;
      this.closeMore();
      if (await this.$refs.confirmDeactivateModal.show()) {
        await ServiceHelper.deleteDataFromBackend(`reportConfig/${idToDelete}/false`);
        this.loadConfigs();
      }
    },
    async copyConfig(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      this.loading = true;
      try {
        const data = await ServiceHelper.loadDataFromBackend(`reportConfig/${this.currentMoreMenu.data._id}`);
        this.backendOperation = null;
        // check for error response
        const decrypted = CryptoHelper.decrypt(data.payload.content);
        const reportConfig = JSON.parse(decrypted);
        reportConfig.timeOfCreation = Constants.getFormattedUTCTimeForMySQL(new Date());
        reportConfig.timeOfModification = reportConfig.timeOfCreation;
        reportConfig.id = `/reportConfig/${uuidv4()}`;
        reportConfig._id = '_new';
        reportConfig.name += ` ${this.$t('general.copy')}`;

        const encryptedContent = CryptoHelper.encrypt(Buffer.from(JSON.stringify(reportConfig)));
        const encryptedTitle = CryptoHelper.encrypt(Buffer.from(reportConfig.name));

        const method = reportConfig._id === '_new' ? 'POST' : 'PUT';
        await ServiceHelper.sendDataToBackend('reportConfig', { content: encryptedContent, name: encryptedTitle }, method);
        await this.loadConfigs();
      } catch (error) {
        this.errorMessage = error;
        console.error('There was an error!', error);
        // TODO: show error in error message box
      } finally {
        this.loading = false;
      }
    },
    async reactivate(evt) {
      evt.preventDefault();
      evt.stopPropagation();
      const reportConfigToReactivate = JSON.parse(JSON.stringify(this.currentMoreMenu.data));
      this.loading = true;
      try {
        await ServiceHelper.sendDataToBackend(`reactivateReportConfig/${reportConfigToReactivate._id}`, {});
        this.showInactiveConfigs = false;
        await this.loadConfigs();
      } catch (error) {
        this.errorMessage = error;
        console.error('There was an error!', error);
        // TODO: show error in error message box
      } finally {
        this.loading = false;
      }
    },
    async openTemplate(template) {
      this.$router.push({
        name: 'Editor',
        params: {
          configId: template.id,
          isTemplate: true,
        },
      });
    },
  },
};
</script>

<style scoped>
@import url('../styles/list.module.css');
@import url('../styles/tiles.module.css');

.more-menu {
  position: absolute;
  width: auto;
  min-width: 140px;
  z-index: 999;
  box-shadow: 3px 3px 7px rgba(52,52,52,0.5);
  background-color: white;
  left: -120px;
  top: 0px;
  cursor:default;
  border-end-end-radius: 5px;
  border-end-start-radius: 5px;
  border-start-end-radius: 5px;
}

.more-menu .more-menu-item {
  text-align: left;
  color: var(--color_almost_black);
  line-height: 20px;
  /* border-bottom: 1px solid #B7ACA6; */
  padding: 10px;
  white-space: nowrap;
  cursor:pointer;
}

.more-menu .more-menu-item:last-of-type {
  border-bottom: none;
}

.more-menu .more-menu-item:hover {
  font-weight: 500;
}

.new {
  padding: 0 18px 0 0;
  color: var(--state-new-color);
}
.in-progress {
  padding: 0 18px 0 0;
  color: var(--state-in-progress-color);
}
.done {
  padding: 0 18px 0 0;
  color: var(--state-done-color);
}

.page-title {
  padding-bottom: 0px;
}

.separatorTitle {
  display: block;
  width: 100%;
  font-size: 24px;
  text-align: left;
  margin-top: 20px;
  padding-top: 10px;
}

.tile.template {
  height: 180px;
  background-color: #fff8f8;
  border: 0.5px solid white
}

@media (max-width: 800px) {
  .tile.template {
    height: 180px;
  }
}
</style>
