<template>
  <div class="loading-indicator-icon"><i class="fas fa-circle-notch"></i></div>
</template>

<script>
/* eslint-disable no-console,no-underscore-dangle,no-alert */

export default {
  components: { },
  name: 'LoadingSpinnerIndicatorComponent',
};
</script>

<style scoped>

.loading-indicator-icon {
  margin: 20px;
  font-size: 30px;
  animation: rotation 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

</style>
